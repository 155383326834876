// @generated by protoc-gen-es v1.3.1 with parameter "target=ts"
// @generated from file cosmos/base/v1beta1/coin.proto (package cosmos.base.v1beta1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';

/**
 * Coin defines a token with a denomination and an amount.
 *
 * NOTE: The amount field is an Int which implements the custom method
 * signatures required by gogoproto.
 *
 * @generated from message cosmos.base.v1beta1.Coin
 */
export class Coin extends Message<Coin> {
  /**
   * @generated from field: string denom = 1;
   */
  denom = '';

  /**
   * @generated from field: string amount = 2;
   */
  amount = '';

  constructor(data?: PartialMessage<Coin>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'cosmos.base.v1beta1.Coin';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'denom', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'amount', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): Coin {
    return new Coin().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): Coin {
    return new Coin().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): Coin {
    return new Coin().fromJsonString(jsonString, options);
  }

  static equals(
    a: Coin | PlainMessage<Coin> | undefined,
    b: Coin | PlainMessage<Coin> | undefined,
  ): boolean {
    return proto3.util.equals(Coin, a, b);
  }
}

/**
 * DecCoin defines a token with a denomination and a decimal amount.
 *
 * NOTE: The amount field is an Dec which implements the custom method
 * signatures required by gogoproto.
 *
 * @generated from message cosmos.base.v1beta1.DecCoin
 */
export class DecCoin extends Message<DecCoin> {
  /**
   * @generated from field: string denom = 1;
   */
  denom = '';

  /**
   * @generated from field: string amount = 2;
   */
  amount = '';

  constructor(data?: PartialMessage<DecCoin>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'cosmos.base.v1beta1.DecCoin';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'denom', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'amount', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DecCoin {
    return new DecCoin().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DecCoin {
    return new DecCoin().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DecCoin {
    return new DecCoin().fromJsonString(jsonString, options);
  }

  static equals(
    a: DecCoin | PlainMessage<DecCoin> | undefined,
    b: DecCoin | PlainMessage<DecCoin> | undefined,
  ): boolean {
    return proto3.util.equals(DecCoin, a, b);
  }
}

/**
 * IntProto defines a Protobuf wrapper around an Int object.
 *
 * @generated from message cosmos.base.v1beta1.IntProto
 */
export class IntProto extends Message<IntProto> {
  /**
   * @generated from field: string int = 1;
   */
  int = '';

  constructor(data?: PartialMessage<IntProto>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'cosmos.base.v1beta1.IntProto';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'int', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): IntProto {
    return new IntProto().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): IntProto {
    return new IntProto().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): IntProto {
    return new IntProto().fromJsonString(jsonString, options);
  }

  static equals(
    a: IntProto | PlainMessage<IntProto> | undefined,
    b: IntProto | PlainMessage<IntProto> | undefined,
  ): boolean {
    return proto3.util.equals(IntProto, a, b);
  }
}

/**
 * DecProto defines a Protobuf wrapper around a Dec object.
 *
 * @generated from message cosmos.base.v1beta1.DecProto
 */
export class DecProto extends Message<DecProto> {
  /**
   * @generated from field: string dec = 1;
   */
  dec = '';

  constructor(data?: PartialMessage<DecProto>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'cosmos.base.v1beta1.DecProto';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'dec', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DecProto {
    return new DecProto().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DecProto {
    return new DecProto().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DecProto {
    return new DecProto().fromJsonString(jsonString, options);
  }

  static equals(
    a: DecProto | PlainMessage<DecProto> | undefined,
    b: DecProto | PlainMessage<DecProto> | undefined,
  ): boolean {
    return proto3.util.equals(DecProto, a, b);
  }
}
