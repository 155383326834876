// @generated by protoc-gen-es v1.3.1 with parameter "target=ts"
// @generated from file haqq/ucdao/v1/tx.proto (package haqq.ucdao.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { Coin } from '../../../cosmos/base/v1beta1/coin_pb';

/**
 * MsgFund allows an account to directly fund the ucdao.
 *
 * @generated from message haqq.ucdao.v1.MsgFund
 */
export class MsgFund extends Message<MsgFund> {
  /**
   * @generated from field: repeated cosmos.base.v1beta1.Coin amount = 1;
   */
  amount: Coin[] = [];

  /**
   * @generated from field: string depositor = 2;
   */
  depositor = '';

  constructor(data?: PartialMessage<MsgFund>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'haqq.ucdao.v1.MsgFund';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'amount', kind: 'message', T: Coin, repeated: true },
    { no: 2, name: 'depositor', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): MsgFund {
    return new MsgFund().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): MsgFund {
    return new MsgFund().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): MsgFund {
    return new MsgFund().fromJsonString(jsonString, options);
  }

  static equals(
    a: MsgFund | PlainMessage<MsgFund> | undefined,
    b: MsgFund | PlainMessage<MsgFund> | undefined,
  ): boolean {
    return proto3.util.equals(MsgFund, a, b);
  }
}

/**
 * MsgFundResponse defines the Msg/Fund response type.
 *
 * @generated from message haqq.ucdao.v1.MsgFundResponse
 */
export class MsgFundResponse extends Message<MsgFundResponse> {
  constructor(data?: PartialMessage<MsgFundResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'haqq.ucdao.v1.MsgFundResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): MsgFundResponse {
    return new MsgFundResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): MsgFundResponse {
    return new MsgFundResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): MsgFundResponse {
    return new MsgFundResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: MsgFundResponse | PlainMessage<MsgFundResponse> | undefined,
    b: MsgFundResponse | PlainMessage<MsgFundResponse> | undefined,
  ): boolean {
    return proto3.util.equals(MsgFundResponse, a, b);
  }
}

/**
 * MsgTransferOwnership allows an account transfer the ownership of shares to another account.
 *
 * @generated from message haqq.ucdao.v1.MsgTransferOwnership
 */
export class MsgTransferOwnership extends Message<MsgTransferOwnership> {
  /**
   * owner is a current owner of the shares in ucdao.
   *
   * @generated from field: string owner = 1;
   */
  owner = '';

  /**
   * new_owner is a new owner of the shares in ucdao.
   *
   * @generated from field: string new_owner = 2;
   */
  newOwner = '';

  constructor(data?: PartialMessage<MsgTransferOwnership>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'haqq.ucdao.v1.MsgTransferOwnership';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'owner', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'new_owner', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): MsgTransferOwnership {
    return new MsgTransferOwnership().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): MsgTransferOwnership {
    return new MsgTransferOwnership().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): MsgTransferOwnership {
    return new MsgTransferOwnership().fromJsonString(jsonString, options);
  }

  static equals(
    a: MsgTransferOwnership | PlainMessage<MsgTransferOwnership> | undefined,
    b: MsgTransferOwnership | PlainMessage<MsgTransferOwnership> | undefined,
  ): boolean {
    return proto3.util.equals(MsgTransferOwnership, a, b);
  }
}

/**
 * MsgTransferOwnershipResponse defines the Msg/TransferOwnership response type.
 *
 * @generated from message haqq.ucdao.v1.MsgTransferOwnershipResponse
 */
export class MsgTransferOwnershipResponse extends Message<MsgTransferOwnershipResponse> {
  constructor(data?: PartialMessage<MsgTransferOwnershipResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'haqq.ucdao.v1.MsgTransferOwnershipResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): MsgTransferOwnershipResponse {
    return new MsgTransferOwnershipResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): MsgTransferOwnershipResponse {
    return new MsgTransferOwnershipResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): MsgTransferOwnershipResponse {
    return new MsgTransferOwnershipResponse().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | MsgTransferOwnershipResponse
      | PlainMessage<MsgTransferOwnershipResponse>
      | undefined,
    b:
      | MsgTransferOwnershipResponse
      | PlainMessage<MsgTransferOwnershipResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(MsgTransferOwnershipResponse, a, b);
  }
}
