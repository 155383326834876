// @generated by protoc-gen-es v1.3.1 with parameter "target=ts"
// @generated from file haqq/liquidvesting/v1/tx.proto (package haqq.liquidvesting.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { Coin } from '../../../cosmos/base/v1beta1/coin_pb';

/**
 * MsgLiquidate represents message to liquidate arbitrary amount of tokens
 * locked in vesting
 *
 * @generated from message haqq.liquidvesting.v1.MsgLiquidate
 */
export class MsgLiquidate extends Message<MsgLiquidate> {
  /**
   * account for liquidation of locked vesting tokens
   *
   * @generated from field: string liquidate_from = 1;
   */
  liquidateFrom = '';

  /**
   * account to send resulted liquid token
   *
   * @generated from field: string liquidate_to = 2;
   */
  liquidateTo = '';

  /**
   * amount of tokens subject for liquidation
   *
   * @generated from field: cosmos.base.v1beta1.Coin amount = 3;
   */
  amount?: Coin;

  constructor(data?: PartialMessage<MsgLiquidate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'haqq.liquidvesting.v1.MsgLiquidate';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'liquidate_from',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 2,
      name: 'liquidate_to',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 3, name: 'amount', kind: 'message', T: Coin },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): MsgLiquidate {
    return new MsgLiquidate().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): MsgLiquidate {
    return new MsgLiquidate().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): MsgLiquidate {
    return new MsgLiquidate().fromJsonString(jsonString, options);
  }

  static equals(
    a: MsgLiquidate | PlainMessage<MsgLiquidate> | undefined,
    b: MsgLiquidate | PlainMessage<MsgLiquidate> | undefined,
  ): boolean {
    return proto3.util.equals(MsgLiquidate, a, b);
  }
}

/**
 * MsgLiquidateResponse defines the Msg/Liquidate response type
 *
 * @generated from message haqq.liquidvesting.v1.MsgLiquidateResponse
 */
export class MsgLiquidateResponse extends Message<MsgLiquidateResponse> {
  /**
   * amount of liquid tokens minted
   *
   * @generated from field: cosmos.base.v1beta1.Coin minted = 1;
   */
  minted?: Coin;

  /**
   * address of erc20 the liquidation denom contract
   *
   * @generated from field: string contract_addr = 2;
   */
  contractAddr = '';

  constructor(data?: PartialMessage<MsgLiquidateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'haqq.liquidvesting.v1.MsgLiquidateResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'minted', kind: 'message', T: Coin },
    {
      no: 2,
      name: 'contract_addr',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): MsgLiquidateResponse {
    return new MsgLiquidateResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): MsgLiquidateResponse {
    return new MsgLiquidateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): MsgLiquidateResponse {
    return new MsgLiquidateResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: MsgLiquidateResponse | PlainMessage<MsgLiquidateResponse> | undefined,
    b: MsgLiquidateResponse | PlainMessage<MsgLiquidateResponse> | undefined,
  ): boolean {
    return proto3.util.equals(MsgLiquidateResponse, a, b);
  }
}

/**
 * MsgLiquidate represents message to redeem arbitrary amount of liquid vesting
 * tokens
 *
 * @generated from message haqq.liquidvesting.v1.MsgRedeem
 */
export class MsgRedeem extends Message<MsgRedeem> {
  /**
   * @generated from field: string redeem_from = 1;
   */
  redeemFrom = '';

  /**
   * destination address for vesting tokens
   *
   * @generated from field: string redeem_to = 2;
   */
  redeemTo = '';

  /**
   * amount of vesting tokens to redeem from liquidation module
   *
   * @generated from field: cosmos.base.v1beta1.Coin amount = 3;
   */
  amount?: Coin;

  constructor(data?: PartialMessage<MsgRedeem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'haqq.liquidvesting.v1.MsgRedeem';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'redeem_from',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 2, name: 'redeem_to', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'amount', kind: 'message', T: Coin },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): MsgRedeem {
    return new MsgRedeem().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): MsgRedeem {
    return new MsgRedeem().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): MsgRedeem {
    return new MsgRedeem().fromJsonString(jsonString, options);
  }

  static equals(
    a: MsgRedeem | PlainMessage<MsgRedeem> | undefined,
    b: MsgRedeem | PlainMessage<MsgRedeem> | undefined,
  ): boolean {
    return proto3.util.equals(MsgRedeem, a, b);
  }
}

/**
 * MsgRedeemResponse defines the Msg/Redeem response type
 *
 * @generated from message haqq.liquidvesting.v1.MsgRedeemResponse
 */
export class MsgRedeemResponse extends Message<MsgRedeemResponse> {
  constructor(data?: PartialMessage<MsgRedeemResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'haqq.liquidvesting.v1.MsgRedeemResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): MsgRedeemResponse {
    return new MsgRedeemResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): MsgRedeemResponse {
    return new MsgRedeemResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): MsgRedeemResponse {
    return new MsgRedeemResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: MsgRedeemResponse | PlainMessage<MsgRedeemResponse> | undefined,
    b: MsgRedeemResponse | PlainMessage<MsgRedeemResponse> | undefined,
  ): boolean {
    return proto3.util.equals(MsgRedeemResponse, a, b);
  }
}
